import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { fetchProducts } from "../../features/productsSlice";
import axios from "axios";

const Products = () => {
  const products = useSelector((state) => state.products.products);
  const adminLoggedIn = useSelector((state) => state.adminLogin.loggedIn);
  const adminToken = useSelector((state) => state.adminLogin.token);
  const status = useSelector((state) => state.products.status);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!adminLoggedIn || !adminToken) {
      navigate("/admin-login");
    } else {
      dispatch(fetchProducts());
    }
  }, [adminLoggedIn, adminToken, navigate, dispatch]);

  const handleDelete = async (productId) => {
    try {
      await axios.post(
        "https://femininestyles-api.onrender.com/api/9BhR3fK7q2xNp5T6/delete-product",
        { productId }
      );
      dispatch(fetchProducts());
    } catch (error) {
      console.log(error);
    }
  };

  if (status === "loading") {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    adminLoggedIn && (
      <div className="products-main">
        <button
          onClick={() => navigate("/admin/products/create-product")}
          className="addproduct-btn"
        >
          Add Product
        </button>
        <Outlet />
        {products.length > 0 ? (
          <div>
            {products.map((product) => (
              <div key={product._id} className="all-products">
                <div className="product-name">{product.name}</div>
                <div className="product-price">N{product.price}</div>
                <button
                  onClick={() => handleDelete(product._id)}
                  className="products-btn"
                >
                  Delete Product
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div>No products available.</div>
        )}
      </div>
    )
  );
};

export default Products;
